



































































import { Observer } from "mobx-vue";
import { Component, Vue, Inject, Ref } from "vue-property-decorator";
import { StakingViewModel } from "../viewmodels/staking-viewmodel";

@Observer
@Component({
  components: {
    StakeForm: () => import("../components/stake-form.vue"),
  },
})
export default class StakingLPDialog extends Vue {
  @Inject() vm!: StakingViewModel;
  @Ref("form") form: any;

  getDMLG() {
    window.open(
      `https://pancakeswap.finance/swap?outputCurrency=${process.env.VUE_APP_TOKEN_ADDRESS}`,
      "_blank"
    );
  }

  async confirmStake() {
    if (this.form.validate()) {
      await this.vm.confirmStake();
    }
  }
}
